exports.components = {
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-moe-tsx": () => import("./../../../src/pages/moe.tsx" /* webpackChunkName: "component---src-pages-moe-tsx" */),
  "component---src-pages-pax-aus-2024-tsx": () => import("./../../../src/pages/pax-aus-2024.tsx" /* webpackChunkName: "component---src-pages-pax-aus-2024-tsx" */),
  "component---src-pages-pixel-expo-2024-tsx": () => import("./../../../src/pages/pixel-expo-2024.tsx" /* webpackChunkName: "component---src-pages-pixel-expo-2024-tsx" */),
  "component---src-pages-uploader-tsx": () => import("./../../../src/pages/uploader.tsx" /* webpackChunkName: "component---src-pages-uploader-tsx" */),
  "component---src-templates-all-galleries-tsx": () => import("./../../../src/templates/all-galleries.tsx" /* webpackChunkName: "component---src-templates-all-galleries-tsx" */),
  "component---src-templates-gallery-tsx": () => import("./../../../src/templates/gallery.tsx" /* webpackChunkName: "component---src-templates-gallery-tsx" */)
}

